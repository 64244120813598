import profile1 from '../assets/ecominera.png'
import profile2 from '../assets/mapal.png'
import profile3 from '../assets/profile3.png'
import profile4 from '../assets/alubry.jpg'
import profile5 from '../assets/semisa.png'


export const TestimonialsData = [
    {
        image : profile1,
        comment: "Empresas con base en la provincia de San Juan, que cuentan con sólidos antecedentes en la construcción y desarrollo de los grandes Proyectos de la Minería nacional",
        name: "MINERÍA"
    },
    {
        image : profile2,
        comment: "Empresas de alcance nacional con más de 50 años de experiencia en construcción de Obras Viales, Civiles, Hidráulicas, Servicios mineros, servicios petroleros y desarrollo inmobiliario.",
        name: "CONSTRUCCIÓN Y MINERÍA"
    },
    {
        image : profile3,
        comment: "Empresas de transportes generales de carga que presta servicios logísticos y fletes en todo el país.",
        name: "TRANSPORTE"
    },
    {
        image : profile4,
        comment: "Empresas de tecnología de primer nivel en infraestructura, asesoramiento en obra, estrictos controles de calidad y seriedad comercial para cubrir todas las necesidades de sus clientes.",
        name: "CONSTRUCCIÓN"
    },
    {
        image : profile5,
        comment: "Empresas de ingeniería y obras civiles con más 20 años de experiencia en el campo de la construcción, nos desarrollamos y expandimos en el área de Ingeniería, Arquitectura, Minería, Energía, como así también en Oil & Gas",
        name: "INGENIERÍA"
    },
   
]