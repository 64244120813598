import React from 'react'
import css from './Testimonials.module.css'
import Hero from '../../assets/cubiertas.png'
import {Swiper, SwiperSlide} from 'swiper/react'
import {TestimonialsData} from '../../data/testimonials'


const Testimonials = () => {
  return (
<div className={css.testimonials}>
<div className={css.wrapper}> 
<div className={css.container}>


<span>SUSTENTABILIDAD</span>
<span> Reciclabilidad </span>

</div>


<img src={Hero} alt="" />

<div className={css.container}>
<span> Calidad </span>
<span>Seriedad </span>

</div>
</div>

<div className={css.reviews}>

 Compromiso con nuestros clientes
</div>

<div className={css.carousel}>
<Swiper slidesPerView={2} slidesPerGroup={1} spaceBetween={20} className={css.tCarousel} loop={true} breakpoints={{
  856: {slidesPerView:3}, 640: {slidesPerView:2}, 0:{slidesPerView:1} 
}}>


{
TestimonialsData.map((testimonial, i) => 
<SwiperSlide>

<div className={css.testimonial}>

<img src={testimonial.image} alt="" />

<span>{testimonial.comment}</span>
<hr/> 
<span>{testimonial.name} </span>
</div>

</SwiperSlide>
)

}

</Swiper>

</div>


</div>


    )
}

export default Testimonials