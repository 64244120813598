import React, { useState} from 'react'
import css from './Header.module.css'
import Logo from '../../assets/logo.png'
import {GiFlatTire} from 'react-icons/gi'
import {GoThreeBars} from 'react-icons/go'
 


const Header = () => {

  const [ShowMenu, setShowMenu] = useState(true)
const toggleMenu = () => {
  setShowMenu((ShowMenu) => !ShowMenu)
}
  return (
    <div className={css.container}>
<div className={css.logo}> 
<img src={Logo} alt="" />
 <span> Luciano Carrizo </span> </div>

<div className={css.right}> 
<div className={css.bars} onClick={toggleMenu} > 
<GoThreeBars/>

</div>

<ul className={css.menu} style={{display: ShowMenu? 'inherit': 'none'}} >

    <li>Transporte</li>
    <li>Off Road</li>
    <li>Agrícola</li>
    <li>Vial-Minería</li>
    <li>Industrial</li>


</ul>

<GiFlatTire className={css.tire}/>




</div>

    </div>
  )
}

export default Header