import React from 'react'
import css from './Footer.module.css'
import {InboxIcon, PhoneIcon, LocationMarkerIcon,  UserIcon, StarIcon} from "@heroicons/react/outline"
import Logo from "../../assets/logo.png"

const Footer = () => {
  return (
<div className={css.cFooterWrapper}>
<hr/>

<div className={css.cFooter}>
<div className={css.logo}>

<img src={Logo} alt="" />
<span>Luciano Carrizo</span>

</div>

<div className={css.block}>
<div className={css.detail}>
<span>Contacto</span>
<span className={css.pngLine}>
<LocationMarkerIcon className={css.icon} />
<span> Trabajos y envíos a todo el país  </span> </span>

<span className={css.pngLine}>
<InboxIcon className={css.icon}/>
<a href="mailto:lucianoecarrizo2@gmail.com">Contacto Vía Mail</a>

</span>

<span className={css.pngLine}>
<PhoneIcon className={css.icon}/>
<a href="tel:3547502122">Enviar Whatsapp y consultar por catálogo y stock</a></span>

</div>

</div>

<div className={css.block}>
<div className={css.detail}>
<span>Teléfono alternativo</span>
<span className={css.pngLine}>
  <UserIcon className={css.icon}/>
  <a href="tel:3517664103"> 3517664103  </a> 
</span>

</div>

</div>

<div className={css.block}>
  <div className={css.detail}>
<span>Truck Center Córdoba</span>
<span className={css.pngLine}>
 <StarIcon className={css.icon}/>
<a target="blank" href="mailto:gustavocarrizo@neuyserv.com"> Gustavo Carrizo </a></span>
  


  </div>
</div>



</div>


<div className={css.copyRight}>
<span>Neumáticos nuevos y recapados</span>
<span>Derechos reservados</span>

</div>

</div>
  )
}

export default Footer